//
// Featured List
// --------------------------------------------------

.featured-list {
  list-style: none;
  padding: 0;

  & li {
    position: relative;
    padding-left: 120px;
  }
}
.featured-list-icon {
  position: absolute;
  top: 0;
  left: 10px;
  display: inline-block;
  width: 80px;
  height: 80px;
  font-size: 36px;
  line-height: 89px;
  text-align: center;
  border: 1px solid @gray-lighter;
  border-radius: 50%;
}
