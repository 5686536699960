// Navbar alignment
//
// Classes for helping space your content when certain navbars are present.

// When a navbar is fixed to the top, add some padding
.with-top-navbar {
  padding-top: 50px;
}

// Fix up counters in the navbar
.navbar-nav {
  > li > a > .badge {
    margin-left: 5px;
  }
}

.navbar-brand-icon {
  float: left;
  margin-right: 5px;
}

// Emphasized navbar (puts a special background-color on it)
.navbar-default {
  .navbar-brand-emphasized {
    &,
    &:hover,
    &:focus {
      background-color: @navbar-default-border;
    }
  }
}

.navbar-inverse {
  .navbar-brand-emphasized {
    &,
    &:hover,
    &:focus {
      color: #fff;
      background-color: @navbar-inverse-border;
    }
  }
}

.navbar-padded .navbar-header {
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: @screen-sm-min) {
  .navbar-padded {
    padding-top: @spacer-y;
    padding-bottom: @spacer-y;
  }
}

// Simpler navbar form controls
.navbar-form-control {
  border: 0;
  box-shadow: none;
}

.navbar-inverse {
  .navbar-form-control {
    background-color: lighten(@navbar-inverse-bg, 7%);

    &:focus {
      color: @navbar-inverse-bg;
      background-color: #fff;
      box-shadow: 0 0 6px rgba(255,255,255,0.5);
    }
  }
}

.navbar-nav {
  > .active > a {
    &,
    &:hover,
    &:focus {
      font-weight: @navbar-nav-active-font-weight; // inherit
    }
  }
}
