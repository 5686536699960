html {
  height: 100%;
}
body {
  // display: flex;
  // flex-direction: column;
  // height: 100vh;
  overflow-x: hidden;
  font-size: 16px;
  background-color: #fefefe;
}
a {
  color: #A18E81;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #A18E81;
    text-decoration: underline;
  }
}
.nav-link {
  color: black;

  &:hover,
  &:focus {
    color: black;
    text-decoration: underline;
  }
}
.nav > li > a {
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
    background-color: #feebdc;
  }
}
.container {
  display: table;
  max-width: 1440px;
  padding-left: 10px;
  padding-right: 10px;
}
.block-fill-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}
.container.main {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
  padding: 60px 0 30px 0;
  background-color: #FFF9F5;
  margin-top: 150px;
  width: 100%;
}
#mc_embed_signup div.mce_inline_error {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  background-color: black !important;
  width: 280px !important;
}
#mce-responses {
  padding-bottom: 10px;
  font-size: 0.875rem;
  font-weight: 500;
}
.heading-big {
  font-size: 2.6rem;
  font-weight: 700;
  margin-top: 0;
}
.heading-small {
  font-size: 1.625rem;
  font-weight: 700;
}
.body-text {
  font-size: 0.875rem;
}
.artwork {
  background-image: url('../img/policy-and-change-ktzhu.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 380px;
  height: 380px;
  z-index: 500;
  position: relative;
}
.blurb {
  width: 380px;
  height: 380px;
  border: 10px solid #FFF9F5;
  padding: 0 50px;
  margin-top: -65px;
  margin-left: -50px;
  display: table;
  position: relative;
  overflow: hidden;
}
.blurb-content {
  display: table-cell;
  vertical-align: middle;
}
.newsletter {
  height: 640px;
  padding: 30px 50px;
  background-color: #FFEBDC;
  margin-top: -325px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.newsletter-signup {
  padding-bottom: 1.2rem;
  align-self: 1.2rem;
}
.newsletter-input {
  background-color: #FFF9F5;
  border: 0;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 0.875rem;
}
.btn-newsletter {
  width: auto;
  height: 40px;
  border-radius: 0;
  font-size: 0.875rem;
  font-weight: 600;
  float: right;
}
.nav {
  padding-bottom: 1.2rem;
}
.nav-item {
  font-weight: 600;
}
.social {
  align-items: flex-end;
}
.social-link {
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  display: inline-block;
}
.social-link--ig,
.social-link--ig:hover,
.social-link--ig:focus {
  background-image: url('../icon/ig.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.social-link--fb,
.social-link--fb:hover,
.social-link--fb:focus {
  background-image: url('../icon/fb.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.social-link--tw,
.social-link--tw:hover,
.social-link--tw:focus {
  background-image: url('../icon/tw.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
.dl-list {
  padding-left: 20px;
}


// ALL OTHER PAGES BESIDES HOMEPAGE
.page-artwork {
  padding-top: 60px;

  .heading-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-bottom: 40px;
  }
  .heading-big {
    margin-right: 20px;
  }
  .divider-line {
    border-top: 10px solid #FFF9F5;
    flex-grow: 2;
  }
  .newsletter {
    margin-top: 0;
    margin-left: 0;
  }
  .artwork {
    width: 300px;
    height: 300px;
  }
  .body-content {
    margin-right: 30px;
  }
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .artwork-wrapper,
  .newsletter-wrapper {
    flex-grow: 1;
  }
  .content-wrapper {
    flex-grow: 2;
  }
}


// SHOP PAGE
.page-artwork .content.content-shop {
  align-items: flex-start;
}
.shop-item {
  margin-bottom: 30px;

  .heading-small > a {
    color: black;
  }
}
.shop-img {
  max-width: 60%;
}

@media (min-width: 1200px) {
  .container {
    width: 1120px;
  }
}


@media (max-width: @screen-md-max) {
  .artwork {
    width: 360px;
    height: 360px;
  }
  .footer {
    margin-top: 50px;
  }
  .blurb {
    margin-top: -30px;
    margin-left: -240px;
    height: 360px;
  }
  .newsletter {
    margin-top: -715px;
    margin-left: -30px;
  }
  .page-artwork {
    .body-content {
      margin-right: 0;
    }
    .newsletter {
      margin-top: 30px;
    }
    .heading-wrapper {
      padding-bottom: 40px;
    }
    .artwork-wrapper,
    .newsletter-wrapper {
      flex-grow: unset;
    }
    .newsletter-wrapper {
      float: none;
      margin: 20px auto;
    }
  }
}


@media (max-width: @screen-sm-max) {
  .blurb {
    margin-left: 30px;
    margin-top: -340px;
    margin-bottom: -30px;
    width: 360px;
    height: 450px;
    padding: 0 35px;
  }
  .newsletter {
    margin: 0 auto;
  }
  .newsletter-wrapper {
    float: none;
    margin: 0 auto;
  }
  .footer-item {
    padding-bottom: 10px;
  }
  .footer-wrapper {
    width: 380px;
    margin: 0 auto;
  }
  .page-artwork {
    .newsletter-wrapper {
      float: left;
    }
  }
}


@media (max-width: @screen-xs-max) {
  .artwork {
    margin: 0 auto;
    width: 320px;
    height: 320px;
  }
  .blurb {
    margin: 0 auto;
    margin-top: -30px;
    margin-bottom: -30px;
    width: 95%;
    height: 330px;
  }
  .newsletter-wrapper {
    float: left;
    padding-left: 10%;
    padding-right: 10%;
  }
  .page-artwork {
    .artwork {
      margin: 0 auto 30px auto;
    }
    .newsletter {
      margin-top: 0;
    }
    .newsletter-wrapper,
    .content-wrapper {
      float: left;
      padding-left: 15%;
      padding-right: 15%;
    }
  }
}


@media (max-width: 480px) {
  .blurb {
    height: 400px;
    margin-top: -30px;
    margin-bottom: -30px;
  }
  .newsletter-wrapper {
    background-color: #FFEBDC;
  }
  .newsletter {
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  .artwork {
    width: 300px;
    height: 300px;
  }
  .footer-wrapper {
    width: auto;
  }
  .footer {
    margin-top: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
  }
  .page-artwork {
    padding-top: 20px;

    .artwork {
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .newsletter-wrapper {
      margin-bottom: 0;
      float: left;
      padding-left: 10%;
      padding-right: 10%;
    }
    .heading-wrapper {
      padding-bottom: 20px;

      .heading-big {
        padding-left: 40px;
      }
    }
    .content-wrapper {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
}
